@import "global";

/**
  BREAK POINTS
  Helpers:
    @include media-breakpoint-up(lg) { (...) }
    @include media-breakpoint-down(lg) { (...) }
    @include media-breakpoint-between(md, lg) { (...) }
    @include media-breakpoint-only(md) { (...) }

  Sizes:
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px
 */
@mixin gradientBg() {
  background: -moz-linear-gradient(top, #ffede2 0%, #ffffff 100%);
  background: -webkit-linear-gradient(top, #ffede2 0%, #ffffff 100%);
  background: linear-gradient(to bottom, #ffede2 0%, #ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffede2', endColorstr='#ffffff', GradientType=0);
}

html, body {
  min-height: 100%;
}
body {
  overflow-y: scroll;
}
#root {
}

.col-nav {
  background-color: $brown;
  background-attachment: fixed;

}

[data-comp-content] {
  position: relative;
  max-width: 860px;
  min-height: calc(100vh - 90px);

  &::before {
    position: absolute;
    display: block;
    content: "";
    width: 100%;
    height: 300px;
    @include gradientBg;
    z-index: 0;
  }
}

.pre-title {
  width: 68px;
  height: 90px;
  position: relative;
  &::before, &::after {
    content: "";
    display: block;
  }
  &::before {
    position: absolute;
    width: 30px;
    height: 30px;
    border-radius: 30px;
    left: 0;
    bottom: 10px;
    background-color: rgba($orange, 0.25);
  }
  &::after {
    width: 1px;
    height: 130%;
    background-color: $orange;
    position: absolute;
    left: -10px;
    bottom: 0;
    transform-origin: 0 100%;
    transform: rotate(40deg);
  }
}

.main {
  min-height: 70vh;
  h1 {
    margin-bottom: $spacer * 2;
  }
  h3 {
    color: #8c8c8c;
  }
}

.edicao-counter {
  margin-bottom: $spacer * 2;
}

.anais {
  margin: $spacer 0 $spacer * 3;
  .title {
    line-height: 1.3em;
    margin-bottom: $spacer / 2;
  }
  .authors {
    color: #595959;
    font-size: .8em;
  }
}

// Medium devices (tablets, 768px and up)
@include media-breakpoint-up(md) {

  .col-nav {
    background-image: url($BASEPATH + '/assets/header-bg.jpg');
    min-height: 100vh;
  }

  #root {
    //@include gradientBg
    &::before {
      position: absolute;
      display: block;
      content: "";
      width: 100%;
      height: 300px;
      @include gradientBg;
      z-index: 0;
    }
  }

  .main {
    min-height: 85vh;
  }

}

// Large devices (desktops, 992px and up)
@include media-breakpoint-up(lg) {

  .col-nav {
    flex: 0 0 35%;
  }
}

// Extra large devices (large desktops, 1200px and up)
@include media-breakpoint-up(xl) {

  [data-comp-content] {
    .container-md {
      padding-left: $spacer * 3;
    }
  }

}
